<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <Form :form="form" @changeForm="(val) => form = val" @saveForm="save"></Form>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "@/components/SystemUser/Form";
import { STATUS_SUCCESS_CODE } from "@/common/constants";
import { RepositoryFactory } from "@/services/api/RepositoryFactory";
import { SYSTEM_USER_MODEL } from "@/constants";
const SystemUserRepository = RepositoryFactory.get(SYSTEM_USER_MODEL);
export default {
    components:{
        Form
    },
    data: function() {
        return {
            form: {
                id: "",
                disable_flg: false,
                user_mail: "",
                user_name: "",
                user_name_disp: "",
                user_occupation: 20,
                user_sys_auth_div: [],
                disp_seq: 0,
                created_by: "",
                updated_by: "",
                login_last_datetime: "",
                login_fail_count: 0,
                password_init_datetime: "",
                account_lock_datetime: ""
            }
        };
    },
    methods: {
        async save() {
            this.form.user_sys_auth_div = this.form.user_sys_auth_div.toString()
            const system_user = await SystemUserRepository.create(this.form);
            if (system_user.status == STATUS_SUCCESS_CODE) {
                this.$toast.success(system_user.data.message, {})
                this.$router.push({ name: "list" })
            } else {
                this.$toast.error(system_user.error.message, {})
            }
        }
    }
}
</script>